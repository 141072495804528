<template>

  <!--          Миний сан-->
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-white border border-gray-200 rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-database-alt"></i>
        Миний сан
      </header>
      <div class="series-container p-4">
        <h2 class="text-xl font-bold">
          2022
        </h2>
        <div class="divider mt-0 after:bg-gray-500 before:bg-gray-500"></div>
        <ul class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
          <li class="related-tiles">
            <a href="javascript:void(0)" class="related-tile">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <!--    <div class="profile-div">-->
  <!--        <div class="profile-card">-->
  <!--            <div class="normal-header">-->
  <!--                <span class="user-name"><i class="el-icon-film"></i><span class="title-left">Миний сан</span></span>-->
  <!--            </div>-->
  <!--            <div class="card-content">-->
  <!--                <div>-->
  <!--                    <div v-if="isLoading">-->
  <!--                        <my-fund-placeholder></my-fund-placeholder>-->
  <!--                    </div>-->

  <!--                    <div v-else class="collections-wrap is-active">-->
  <!--                        <div class="archive">-->
  <!--                            <article class="article" v-for="item in episodes" :key="item.index">-->
  <!--                                <div class="series-container">-->
  <!--                                    <div>-->
  <!--                                        <div class="related-tiles">-->
  <!--                                            <a :href="`/#/watch/series/episode/`+item.id" class="related-tile">-->
  <!--                                                <div class="related-social">-->
  <!--                                                    <el-button size="mini" class="x-mini" type="primary" icon="el-icon-share"></el-button>-->
  <!--                                                    <el-tag size="mini" effect="dark" type="info" v-show="item.age_limit!= null">{{ item.age_limit }}</el-tag>-->
  <!--                                                </div>-->
  <!--                                                <img-->
  <!--                                                    src="https://animax.mn/assets/max/images/poster.png"-->
  <!--                                                    v-lazy:background-image="$helper.fixImagePath(item.image)"-->
  <!--                                                    class="poster"-->
  <!--                                                />-->
  <!--                                                <div class="related-details"  v-if="$app == 'wemax'">-->
  <!--                                                    <span class="related-title"  v-if="$app == 'wemax'">{{ item.title }}</span>-->
  <!--                                                    <span class="related-info" v-html="item.description"></span>-->
  <!--                                                </div>-->
  <!--                                            </a>-->
  <!--                                        </div>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                            </article>-->
  <!--                        </div>-->

  <!--                        <div class="load-more-wrap has-text-centered">-->
  <!--                            <loader v-if="isLoadingMore"></loader>-->
  <!--                            <a-->
  <!--                                v-else-->
  <!--                                href="javascript:void(0)"-->
  <!--                                @click="getEpisodes(page, true)"-->
  <!--                                class="load-more-button"-->
  <!--                            >Цааш үзэх</a-->
  <!--                            >-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>

// import Loader from "../../components/loader.vue";
// import MyFundPlaceholder from "../../components/placeholders/my-fund-placeholder.vue";
import {mapActions} from "vuex";
import axios from "axios";

export default {
  name: 'MyFund',
  props: ["url", "hlink"],
  components: {
    // Loader,
    // MyFundPlaceholder,
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      page: 1,
      episodes: [],
    };
  },

  computed: {

  },

  created() {
    // this.getEpisodes(1, false);
  },

  methods: {
    ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),

    getEpisodes(page, isMore) {
      if (this.page == 1) {
        this.isLoading = true;
      }
      if (isMore) {
        this.isLoadingMore = true;
      }

      axios
          // .get(`${this.$props.url}?page=${page}`)
          .get(`https://max.test/api/m/anime/list/2??page=${page}`)
          .then(({data}) => {
            data.data.forEach((item) => {
              this.episodes.push(item);
            });
            this.page = data.current_page + 1;
            this.isLoading = false;
            this.isLoadingMore = false;
            console.log(this.episodes);
          })
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
            this.isLoadingMore = false;
          });
    },

    gotoWatch(id) {
      this.setBackLink(this.$route.path);
      this.$router.push(`/watch/episode/${id}`);
    }
  },
};
</script>
